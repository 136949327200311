import { Box, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { FC, useEffect, useState } from "react";
import DataObjectIcon from '@mui/icons-material/DataObject';
import { dataApiService } from "../services";
import { Query } from "../types";
import { useDispatch, useSelector } from "react-redux";
import { RootState, Dispatch } from "../store";

const DVSideBard:FC = () => {

    const dispatch = useDispatch<Dispatch>();
    const [queries, setQueries] = useState<Query[]>();
    const selectedQuery = useSelector((state: RootState) => state.DVEvents.query);

    useEffect(() => {

        getQueriesData();
    }, []);

    const getQueriesData = async () => {

        const getResponse = await dataApiService.getQueries();
        getResponse && setQueries(getResponse.data);

        // Set Init Query
        selectedQuery === undefined && dispatch.DVEvents.setQuery(getResponse.data[0].file);
        
    }

    const handleListItemClick = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>,
        file: string,
    ) => {
        dispatch.DVEvents.setQuery(file);
        dispatch.DVEvents.setCellIdx(-1);
    };

    return (
        <>
            <Box sx={{ 
                height: '600px', 
                minWidth: 180, 
                padding: 2,
                borderRight: 1,
                borderColor: 'rgba(0, 0, 0, 0.12)'
            }}>
                <List dense={true} component="nav" aria-label="main mailbox folders">
                    { queries?.map((query: Query, idx: number) => {
                        return (
                            <ListItemButton key={idx} 
                                selected={selectedQuery === query.file}
                                onClick={(e) => handleListItemClick(e, query.file)}>
                                <ListItemIcon sx={{minWidth: '36px'}} >
                                    <DataObjectIcon />
                                </ListItemIcon>
                                <ListItemText primary={query.title} />
                            </ListItemButton>
                        )
                    }) }
                </List>
            </Box>
        </>
    )
}

export default DVSideBard;
