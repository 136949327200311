import { FC, useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";
import 'devextreme/dist/css/dx.light.css';
import { dataApiService } from "../services";
import { Dispatch, RootState } from "../store";
import { useDispatch, useSelector } from "react-redux";
import DataGrid, { Column, Export, FilterRow, Item, MasterDetail, Scrolling, SearchPanel, Toolbar } from "devextreme-react/data-grid";
import { FormControlLabel, Switch, Typography } from "@mui/material";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { exportDataGrid as exportPdfDataGrid } from 'devextreme/pdf_exporter';
import { exportDataGrid as exportExcelDataGrid } from 'devextreme/excel_exporter';
import { jsPDF } from 'jspdf';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import DetailComponent from "./DetailComponent";
import { Button } from "devextreme-react";

type rowStatus = {
    rIdx: number;
    cIdx: number;
    value: boolean;
}

const DVContent: FC = () => {

    const dispatch = useDispatch<Dispatch>();
    const [columns, setColumns] = useState<string[]>([]);
    const [data, setData] = useState<any>([]);
    const [rowStatus, setrowStatus] = useState<rowStatus[]>([]);
    const selectedQuery = useSelector((state: RootState) => state.DVEvents.query);
    const selectedCellIdx = useSelector((state: RootState) => state.DVEvents.cellIdx);
    const [showOptionModal, setShowOptionModal] = useState<boolean>(false);
    const [switchChecked, setSwitchChecked] = useState<boolean>(true);
    const [showFilterRow, setShowFilterRow] = useState<boolean>(false);

    useEffect(() => {
        getFileData(selectedQuery);
    }, [selectedQuery]);

    /**
     * Get Grid Data from Server (from text file of server)
     */
    const getFileData = async (selectedQuery: string | undefined) => {

        const getResponse = selectedQuery && await dataApiService.getFileData(selectedQuery);
        
        if (getResponse && getResponse.data) {

            let keys: string[] = [];
            let data: any = [];

            if (Array.isArray(getResponse.data)) {
                // Set columns from response
                keys = Object.keys(getResponse.data[0]);
                data = getResponse.data
            } else {
                const fakeKeys = Object.keys(getResponse.data);
                fakeKeys.forEach(key => {
                    data = [...data, ...getResponse.data[key]];
                });
                keys = Object.keys(data[0]);
            }

            setColumns(keys);
            setData(data);
            setrowStatus([]);
        }
    }

    /**
     * Exporting Excel and PDF
     * @param e 
     */
    const onExporting = (e: any) => {

        if (e.format === 'pdf') {

            const doc = new jsPDF();

            exportPdfDataGrid({
                jsPDFDocument: doc,
                component: e.component,
                indent: 5,
            }).then(() => {
                doc.save('data.pdf');
            });

        } else if (e.format === 'excel') {
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet('Main sheet');

            exportExcelDataGrid({
                component: e.component,
                worksheet,
                autoFilterEnabled: true,
            }).then(() => {
                workbook.xlsx.writeBuffer().then((buffer) => {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'data.xlsx');
                });
            });
            e.cancel = true;
        }
    }

    /**
     * Get Object Cell
     * @param props 
     * @returns 
     */
    const renderCustomCell = useCallback((props: any) => {
        const myStatus = getRowStatus(props.row.rowIndex, props.column.index);
        const text = myStatus ? "Hide" : "Show";

        const detailClick = () => {
            dispatch.DVEvents.setCellIdx(props.column.index);
            if (myStatus) {
                updateCellStatus(props.row.rowIndex, props.column.index, false);
                props.component.collapseRow(props.key);
            } else {
                // Update RowStatus
                updateCellStatus(props.row.rowIndex, props.column.index, true);
                props.component.expandRow(props.key);
            }
        };

        if (selectedCellIdx < 0) {
            props.component.collapseRow(props.key);
        }

        return (
            <Typography sx={{cursor: 'pointer', textDecoration: 'underline'}} fontSize={14} onClick={detailClick}>{text}</Typography>
        );
    }, [rowStatus, selectedCellIdx]);


    /**
     * Get Row Status by index
     * @param rIdx 
     * @param cIdx 
     * @returns 
     */
    const getRowStatus = useCallback((rIdx: number, cIdx: number): boolean => {

        const r = rowStatus.filter((s: rowStatus) => s.rIdx === rIdx && s.cIdx === cIdx );

        if (r.length > 0) {
            return r[0].value;
        } else {
            return false;
        }
    }, [rowStatus]);

    /**
     * Update Cell Status
     * @param rIdx 
     * @param cIdx 
     * @param value 
     */
    const updateCellStatus = useCallback((rIdx: number, cIdx: number, value: boolean) => {

        const r = rowStatus.filter((s: rowStatus) => s.rIdx === rIdx && s.cIdx === cIdx);
        let nStatus: rowStatus[] = rowStatus.map((s: rowStatus) => {
            return s.rIdx === rIdx ? {...s, value: false} : s;
        });

        if (r.length < 1) {
            nStatus = [...nStatus, {rIdx, cIdx, value}];
        } else {
            nStatus = nStatus.map((s: rowStatus) => {
                return s.rIdx === rIdx && s.cIdx === cIdx ? {rIdx, cIdx, value: value} : s;
            });
        }

        setrowStatus(nStatus);
    }, [rowStatus]);


    /**
     * Columns Option Changed
     * @param columns 
     */
    const handleColumnOption = (columns: string[]) => {

        setColumns(columns);
        setShowOptionModal(false);
    }

    /**
     * Handle Change Scroll Mode
     * @param event 
     */
    const handleOnScrollChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSwitchChecked(event.target.checked);
    };

    return (
        <>
            <Box sx={{p: 2, overflow: 'auto', width: 900}}>
                <DataGrid
                    dataSource={data}
                    showBorders={true}
                    showRowLines={true}
                    rowAlternationEnabled={true}
                    columnAutoWidth={true}
                    onExporting={onExporting}
                >
                    { columns.map((column: string, idx: number) => {
                        if (typeof data[0][column] === 'string' || typeof data[0][column] === 'number' || data[0][column] == null) {
                            return <Column dataField={column} key={idx} />
                        } else {
                            return <Column caption={column.charAt(0).toUpperCase() + column.slice(1)} 
                                cellRender={renderCustomCell} key={idx} />
                        }
                    }) }

                    <MasterDetail
                        enabled={true}
                        component={DetailComponent}
                    />

                    <Scrolling mode={switchChecked ? 'paging' : 'infinite'}></Scrolling>

                    <FilterRow visible={showFilterRow}
                        applyFilter={{
                            key: 'auto',
                            name: 'Immediately',
                        }} />

                    <SearchPanel visible={true}
                        width={240}
                        placeholder="Search..." />

                    <Export enabled={true} formats={['pdf', 'excel']} />

                    <Toolbar>
                        <Item location="before" name="exportButton" />
                        <Item location="before">
                            <Button icon='preferences'
                                onClick={() => setShowOptionModal(true)}
                                hint="Column Options"
                            />
                        </Item>
                        <Item location="before">
                            <FormControlLabel
                                control={<Switch checked={switchChecked} onChange={handleOnScrollChange} />}
                                label="Record Scroll"
                                sx={{ marginLeft: '15px' }}
                            />
                        </Item>

                        <Item location="after">
                            <FilterAltIcon role="button"
                                sx={{ cursor: 'pointer', color: showFilterRow ? '#206C91' : '#ddd' }}
                                onClick={() => setShowFilterRow(!showFilterRow)} />
                        </Item>
                        <Item location="after" name="searchPanel" />
                    </Toolbar>
                </DataGrid>
            </Box>
        </>
    )
}

export default DVContent;