import { FC } from "react";
import DataViewer from "./pages/DataViewer";

const App: FC = () => {

	return (
		<>
			<DataViewer />
		</>
	)
}

export default App;