import { createModel } from "@rematch/core";
import { RootModel } from ".";
import { DVEvent } from "../../types";

export const initState: DVEvent = {
    query: undefined, // selected Query Id,
    cellIdx: -1,
    subCellIdx: -1
}

export const DVEvents = createModel<RootModel>()({
    state: initState,
    reducers: {
        init: () => initState,
        setQuery: (state: DVEvent, payload: string) => {
            return {
                ...state,
                query: payload
            }
        },
        setCellIdx: (state: DVEvent, payload: number) => {
            return {
                ...state,
                cellIdx: payload
            }
        },
        setSubCellIdx: (state: DVEvent, payload: number) => {
            return {
                ...state,
                subCellIdx: payload
            }
        }
    },
    effects: (dispatch) => ({
        onInit(_payload, _state) {
            dispatch.DVEvents.init();
        }
    }),
});
