import { FC } from "react";
import { Box, Card, CardContent, CardHeader, Container, Divider, Grid } from "@mui/material";
import DVToolBar from "../components/DVToolBar";
import DVSideBar from "../components/DVSideBar";
import DVContent from "../components/DVContent";
import "../assets/dxgrid.css";

const DataViewer:FC = () => {

    return (
        <>
            <Container maxWidth="lg" sx={{paddingTop: 4}}>
                <Card>
                    <CardHeader 
                        title="Data Viewer"
                    />

                    <Divider />

                    <Box sx={{display: "flex"}}>
                        <DVSideBar />
                        <DVContent />
                    </Box>
                </Card>
            </Container>
        </>
    )
}

export default DataViewer;