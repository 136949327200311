import ApiClient, { ApiConfiguration } from "./ApiClient";
import DataApiService, { DataApiClient } from "./DataApi";

export let dataApiService: DataApiService;

export const initApiService = () => {
    const dataApiConfig = new ApiConfiguration();

    dataApiConfig.baseUrl = process.env.NODE_ENV == 'production' ? '' :"http://localhost:5000/";
    const dataApiClient = new DataApiClient(
        new ApiClient(dataApiConfig)
    );
    dataApiService = new DataApiService(dataApiClient);
};